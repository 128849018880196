import {OrderByCreateRequest, User, UserCreationRequest} from "../../shared/entities/Users/User";
import {CustomError} from "../../shared/errors/CustomErrors";
import {Option} from "../../shared/utilities/OptionT";
import {APIDao, Requester} from "../APIRequester";
import {Dealership, DealershipMinInfo} from "../../shared/entities/Users/Dealership";
import {RegistrationRequest} from "../../shared/entities/registrationRequest/RegistrationRequest";

/**
 * UserRepository.
 *
 * Responsible for fetching data from backend
 * by callind APIDao and handling various data
 * coming from API
 *
 * Data is passed to UserService in a form of
 * an @type Option<T> type, where @param{Some: T} is either
 * a valid data, expected from the server,
 * or, in case of error, a default safe value,
 * for an object of array (kinda like default
 * values for types in Go),and @param{None: CustomError}
 * is either an error or undefined
 */
export class UserRepository<T> {
    private dao: Requester

    constructor(dao: Requester) {
        this.dao = dao
    }

    /**
     * getUserList.
     *
     * @returns {Promise<Option<Array<User>>>}
     *
     */
    public async getUserList(): Promise<Option<Array<User>>> {
        return await this.dao.getRequest<{ USERS: Array<User> }>("admin/new-users-list", {}).then(res => {
            const result: Option<Array<User>> = {
                Some: res.data?.USERS,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Array<User>> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async getUserListAll(params: {}): Promise<Option<{ users: Array<User>, count: number }>> {
        return await this.dao.getRequest<{
            users: Array<User>,
            count: number
        }>("admin/users_list_all", {params: {...params}}).then(res => {
            const result: Option<{ users: Array<User>, count: number }> = {
                Some: {
                    users: res?.data?.users,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ users: Array<User>, count: number }> = {
                //@ts-ignore
                Some: {
                    users: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }

    public async getDealershipAll(params: {}): Promise<Option<{ dealerships: Array<Dealership>, count: number }>> {
        return await this.dao.getRequest<{
            dealerships: Array<Dealership>,
            count: number
        }>("admin/dealership-all", {params: {...params}}).then(res => {
            const result: Option<{ dealerships: Array<Dealership>, count: number }> = {
                Some: {
                    dealerships: res?.data?.dealerships,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ dealerships: Array<Dealership>, count: number }> = {
                //@ts-ignore
                Some: {
                    dealerships: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }

    public async getAll(data: T, body: T): Promise<Option<T>> {
        return await this.dao.postRequest<T>(`admin/users-all`, {
            params: {...data},
            data: {...body}
        })
            .then(res => {
                const result: Option<T> = {
                    Some: res?.data
                }
                return result
            })
    }

    public async getCompanyAll(data: T, body: T): Promise<Option<T>> {
        return await this.dao.postRequest<T>(`admin/company/all`, {
            params: {...data},
            data: {...body}
        })
            .then(res => {
                const result: Option<T> = {
                    Some: res?.data
                }
                return result
            })
    }

    public async getCompany(id: {}): Promise<Option<T>> {
        return await this.dao.getRequest<T>(`admin/company/${id}`, {params: {}}).then(res => {
            const result: Option<T> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<T> = {
                Some: {} as T,
                None: err
            }
            return result
        })
    }
    public async createCompany(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/company/create", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }

    public async updateCompany(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/company/update", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }

    public async getDealershipMinInfoAll(params: {}): Promise<Option<Array<DealershipMinInfo>>> {
        return await this.dao.getRequest<any>("admin/dealership-min-info-all", {params: {...params}}).then(res => {
            const result: Option<any> = {
                Some: res?.data
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                //@ts-ignore
                Some: [],
                None: err
            }
            return result
        })
    }

    public async getUser(login: {}): Promise<Option<User>> {
        return await this.dao.getRequest<User>("admin/user", {params: {login}}).then(res => {
            const result: Option<User> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<User> = {
                Some: {} as User,
                None: err
            }
            return result
        })
    }

    public async getUserId(id: {}): Promise<Option<User>> {
        return await this.dao.getRequest<User>("admin/userId", {params: {id}}).then(res => {
            const result: Option<User> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<User> = {
                Some: {} as User,
                None: err
            }
            return result
        })
    }

    public async getDealership(params: {}): Promise<Option<Dealership>> {
        return await this.dao.getRequest<Dealership>("admin/dealership", {params: {...params}}).then(res => {
            const result: Option<Dealership> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<Dealership> = {
                Some: {} as Dealership,
                None: err
            }
            return result
        })
    }


    /**
     * createUser.
     *
     * @param {any} data
     * @returns {Promise<Option<number>>}
     */
    public async createUser(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/create-user", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }

    public async restorePassword(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/restore-password", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }

    public async changePassword(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/user_change_password", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }
    public async getLoginCode(code: any): Promise<Option<number>> {
        return await this.dao.getRequest<number>("admin/get-login-code", {params: {code}}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }

    /**
     * updateUser
     *
     * @param {any} data
     * @returns {Promise<Option<number>>}
     */
    public async updateUser(data: any): Promise<Option<number>> {
        return await this.dao.postRequest<number>("admin/update-user", {data}).then(res => {
            const result: Option<number> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<number> = {
                Some: 0,
                None: err
            }
            return result
        })
    }


    public async deleteUser(id: number) {
        await this.dao.putRequest<User>(`admin/delete/${id}`, {});
    }

    public async deleteCompany(id: number) {
        await this.dao.putRequest<User>(`admin/company/delete/${id}`, {});
    }

    public async deleteDealership(id: number) {
        await this.dao.putRequest<User>(`admin/delete-dealership/${id}`, {});
    }

    public async companyRegistered(id: number) {
        return await this.dao.postRequest<{}>(`admin/company-registered/${id}`, {}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async inviteCreate(id: number) {
        return await this.dao.postRequest<{}>(`admin/invite-create/${id}`, {}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async setNewPassword(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<any>("admin/set-new-password", {data}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async userCreationRequest(data: UserCreationRequest): Promise<Option<any>> {
        return await this.dao.postRequest<any>("api/request-registration/create", {data}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async orderByCatalogCreate(data: OrderByCreateRequest): Promise<Option<any>> {
        return await this.dao.postRequest<any>("mail/order-by-create", {data}).then(res => {
            const result: Option<any> = {
                Some: res.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: {} as -1,
                None: err
            }
            return result
        })
    }

    public async getRequestRegistrationAll(params: {}): Promise<Option<{
        registrationUsers: Array<RegistrationRequest>,
        count: number
    }>> {
        return await this.dao.getRequest<{
            registrationUsers: Array<RegistrationRequest>,
            count: number
        }>("api/request-registration/all", {params: {...params}}).then(res => {
            const result: Option<{ registrationUsers: Array<RegistrationRequest>, count: number }> = {
                Some: {
                    registrationUsers: res?.data?.registrationUsers,
                    count: res?.data?.count
                }
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<{ registrationUsers: Array<RegistrationRequest>, count: number }> = {
                //@ts-ignore
                Some: {
                    registrationUsers: [],
                    count: 0
                },
                None: err
            }
            return result
        })
    }

    public async confirmCompleted(id: number): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`api/request-registration/confirm-completed/${id}`, {}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }

    public async dealershipFeeState(data: any): Promise<Option<any>> {
        return await this.dao.postRequest<{}>(`admin/dealership-fee-state`, {data}).then(res => {
            const result: Option<any> = {
                Some: res?.data,
            }
            return result
        }).catch((err: CustomError) => {
            const result: Option<any> = {
                Some: [],
                None: err
            }
            return result
        })
    }
}

export const UserRepositoryInstance = new UserRepository(APIDao) 
