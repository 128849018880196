import {
    Box,
    Flex,
    Text,
    ChakraProps,
    Link,
    Select,
    Tooltip,
    Collapse,
    useDisclosure, useBreakpointValue
} from "@chakra-ui/react";
import React, {FC, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {useLocation, useNavigate} from "react-router";
import {useAuth} from "../../hooks/AuthHook";
import {URLPaths} from "../../config/application/URLPaths";
import PopoverCart from "../../views/CartBasket/PopoverCart";
import {useAppSelector} from "../../redux/Store";
import {BoxRowStyleConfig, BoxStyleConfig} from "../../views/Forms/FormStyleConfigs";
import {ChevronDownIcon, Icon} from "@chakra-ui/icons";
import {Filter} from "../Filter/Filter";
import {UserController} from "../../controllers/UserController";
import {Dealership} from "../../shared/entities/Users/Dealership";
import {usePrivileges} from "../../hooks/PrivilegesProvider";
import {ActionTypes} from "../../redux/NavBar/NavBarReducer";
import {generalColor, generalView} from "../../theme/foundations/breakpoints";
import {
    BannerIcon,
    BannerIconMobile,
    CallIcon,
    LogoMobile,
    Logo
} from "../Inputs/Icons/Icons";
import {StateController} from "../../controllers/StateController";
import NavBarLinkButton from "../Link/NavBarLinkButton";
import Cart from "../../views/CartBasket/Cart";
import CartButton from "../../views/CartBasket/CartButton";
import MenuLinks from "../../views/Menu/Menu";
import MenuButton from "../../views/Menu/MenuButton";
import {AuthorizationController} from "../../controllers/AuthController";

export const Navbar: FC = () => {
    const {
        isOpen: isMenuOpen,
        onToggle: onMenuToggle,
        onClose: onMenuClose,
    } = useDisclosure();
    const {
        isOpen: isCartOpen,
        onToggle: onCartToggle,
        onClose: onCartClose
    } = useDisclosure();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userController = new UserController(dispatch);
    const {priv} = usePrivileges();
    const NavbarStyleConfig: ChakraProps = {
        h: {
            // xl: "80px",
        },
        pt: {base: 2, lg: 10},
        paddingBottom: {md: 6},
        fontFamily: "Poppins"
    };
    const stateController = new StateController(dispatch)
    const location = useLocation();
    const searchReset = useAppSelector((state) => state.NavBarReducer.searchReset);
    const authDealership = useAppSelector(state => state.AuthReducer.dealership);
    const [resetFilter, setResetFilter] = useState(false);
    const [newUserCount, setNewUserCount] = useState(0);
    const authController = new AuthorizationController(dispatch)

    const [dealerships, setDealerships] = useState<Dealership[]>([]);
    const {isAuth, handleAuthChange} = useAuth()

    const products = useAppSelector((state) => state.ProductReducer.productList);
    const orders = useAppSelector((state) => state.OrderReducer.orderClientList);
    const users = useAppSelector((state) => state.UserReducer.companyAll);

    let isMobile = useBreakpointValue({base: true, sm: true, md: true, lg: false, xl: false, "2xl": false})
    const LogOut = () => {
        authController.logOut().then(() => {
            sessionStorage.clear();
            handleAuthChange(false);
            window.location.reload();
        });
        navigate(URLPaths.HOME.link);
    };
    useEffect(() => {
        if (isAuth) {
            // userController.getUser(sessionStorage.getItem("LOGIN") as string, true).then(user => {
            userController.getUser(sessionStorage.getItem("LOGIN") as string, true).then(user => {
                if (user.Some) {
                    sessionStorage.setItem("Name", user.Some.login);
                }
                if (user.Some.dealerships.length === 0) {
                    dispatch({type: "SET_DEALERSHIP", payload: {}});
                }
                if (user.Some.dealerships.length === 1) {
                    sessionStorage.setItem("DEALERSHIP", user.Some.dealerships[0]?.name);
                    if (user.Some.dealerships[0].id) {
                        dispatch({type: "SET_DEALERSHIP", payload: user.Some.dealerships[0]});
                        setDealerships(user.Some?.dealerships);
                        return;
                    }
                }
                let dealer = user.Some.dealerships.find(value => value.name === sessionStorage.getItem("DEALERSHIP"))
                if (dealer && dealer.id !== undefined) {
                    setBlink(true);
                    setVal(dealer.id.toString());
                }
                if (user.Some.dealerships) {
                    user.Some?.dealerships.push({name: "Choose a Dealer", phone: "", id: 0})

                    setDealerships(user.Some?.dealerships);
                }
            })
            if (priv?.ORDER_EDIT || priv?.PRODUCT_EDIT) {
                userController.getDealershipMinInfoAll({}).then(() => {
                })
            }
        }
    }, [isAuth]);


    const handleResetFilter = () => {
        setResetFilter((prevResetFilter) => !prevResetFilter);
    };
    useEffect(() => {
        if (searchReset) {
            handleResetFilter()
            dispatch({type: ActionTypes.SEARCH_RESET, payload: false});
        }
    }, [searchReset]);

    useEffect(() => {
        // console.log("NavBar 1")
        stateController.getStates().then(() => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if ((products || orders || users) && priv?.ADMINISTRATOR_VIEW) {
            userController.getUserList().then(res => {
                setNewUserCount(res.Some?.length)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products, orders, users]);

    const setNewFilterValue = (val: any, modal: boolean) => {
        if (typeof val.search !== 'function' && val !== "") {
            dispatch({type: ActionTypes.SEARCH_TEXT, payload: val});
        }
    }
    const [val, setVal] = useState<string>("0");
    const [blink, setBlink] = useState(false);

    const updateDealership = (value: string) => {
        setVal(value)
        const dealer = dealerships.find((elem) => elem?.id === Number(value));
        if (dealer) {
            setBlink(true);
            sessionStorage.setItem("DEALERSHIP", dealer?.name || "")
            dispatch({type: "SET_DEALERSHIP", payload: dealer});
        }
    }

    const handleLinkHome = () => {
        handleResetFilter()
        dispatch({type: ActionTypes.SEARCH_RESET, payload: true});
        navigate(URLPaths.HOME.link);
    };

    return (
        <Flex {...NavbarStyleConfig} flexDir="column">
            {isMobile ?
                <Box>
                    <Flex alignItems="center">
                        <Flex ml={isAuth ? "auto" : "unset"} justifyContent={isAuth ? "unset" : "space-between"}
                              alignItems="center"
                              w={isAuth ? "unset" : "100%"}>
                            <MenuButton onClick={() => {
                                if (!location.pathname.includes(URLPaths.AUTH.link) && !isAuth) {
                                    navigate(URLPaths.AUTH.link + location.pathname);
                                } else {
                                    onMenuToggle();
                                    onCartClose();
                                }
                            }}/>
                            <CartButton onClick={() => {
                                onCartToggle();
                                onMenuClose();
                            }}/>
                        </Flex>
                    </Flex>
                    {isAuth && dealerships.length > 2 && (
                        <Box pt={2}>
                            <Tooltip
                                label={authDealership === undefined ? "no selected" : authDealership?.name}
                            >
                                <Select
                                    icon={<ChevronDownIcon fontSize={24}/>}
                                    borderColor={blink ? "green" : "red"}
                                    value={val}
                                    fontSize={14}
                                    width="100%"
                                    onChange={(ev) => {
                                        updateDealership(ev.target.value);
                                        ev.target.blur();
                                    }}
                                >
                                    {dealerships?.map((elem) => (
                                        <option key={elem.id} value={elem.id} hidden={elem.id === 0}>
                                            {elem.name}
                                        </option>
                                    ))}
                                </Select>
                            </Tooltip>
                        </Box>
                    )}
                    <Collapse in={isMenuOpen} animateOpacity>
                        <Box py={2}>
                            <Box p={4} style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "1.5px solid #6B7280",
                                boxShadow: "0px 4px 32px #B3B2B2",
                            }}>
                                <MenuLinks/>
                            </Box>
                        </Box>
                    </Collapse>
                    <Collapse in={isCartOpen} animateOpacity>
                        <Box py={2}>
                            <Box p={4} style={{
                                background: "#FFFFFF",
                                borderRadius: "5px",
                                border: "1.5px solid #6B7280",
                                boxShadow: "0px 4px 32px #B3B2B2",
                            }}>
                                <Cart onCheckout={onCartClose}/>
                            </Box>
                        </Box>
                    </Collapse>
                    <Flex pt={4}>
                        <Icon as={LogoMobile} onClick={() => handleLinkHome()}
                              py={1}
                              width={["50%"]} // Adjust sizes for different breakpoints
                              height={["50%"]} // Adjust sizes for different breakpoints
                              cursor="pointer"
                        />
                        <Icon as={BannerIconMobile} onClick={() => navigate(URLPaths.GREAT_SAVE.link)}
                              pl={2}
                              width={["50%"]} // Adjust sizes for different breakpoints
                              height={["50%"]} // Adjust sizes for different breakpoints
                              cursor="pointer"
                              color="gray.200"
                              _hover={{color: "gray.500"}}
                              transition="all 0.2s ease-in-out" // Smooth transition for the hover effect
                        />
                    </Flex>
                    <Flex w={"100%"} flexDir={isAuth ? "row" : "column"}>
                        {(location.pathname.includes(URLPaths.PRODUCTS.link) || location.pathname === URLPaths.HOME.link) && (
                            <Filter
                                updateFunc={setNewFilterValue}
                                uniqueName="search"
                                reset={resetFilter}
                                height="40px"
                                width="100%"
                                placeholder="Search"
                                style={{border: "1.6px solid", borderColor: "gray.600"}}
                            />
                        )}
                        {isAuth &&
                            <Flex pt={2} pl={2} justifyContent="flex-end" ml={"auto"}>
                                <NavBarLinkButton
                                    fontSize={14}
                                    fontWeight="sm"
                                    text="Order History"
                                    onClick={() => navigate(URLPaths.ORDER_HISTORY.link)}
                                />
                            </Flex>
                        }
                    </Flex>
                    <Box py={2}>
                        <Flex alignItems="center" color={generalColor.primaryHeading} fontWeight={600} pb={2}>
                            <Icon as={CallIcon}/>
                            <Link href="tel:8002605051" _hover={{textDecoration: "underline"}}>
                                <Text paddingLeft={2} fontFamily="Poppins">800.260.5051</Text>
                            </Link>
                        </Flex>
                        <Link color={generalColor.primaryText}
                              href="mailto:info@akdealerservices.com" _hover={{textDecoration: "underline"}}>
                            info@akdealerservices.com
                        </Link>
                    </Box>
                </Box>
                :
                <Flex width={"100%"} direction={{base: "column", lg: "row"}}>
                    <Box {...BoxStyleConfig} maxWidth={{base: "100%", md: "80%", lg: "31%"}}>
                        <Box {...BoxRowStyleConfig} paddingBottom={2}>
                            <Icon as={Logo} onClick={() => handleLinkHome()}
                                  py={1}
                                  width={["100%"]}
                                  height={["100%"]}
                                  maxW={generalView.logo.width}
                                  cursor="pointer"
                            />
                        </Box>
                        <Flex direction={{base: "column", "2xl": "row"}}>
                            <Flex alignItems="center" color={generalColor.primaryHeading} fontWeight={600}
                                  paddingRight={2}>
                                <Icon as={CallIcon}/>
                                <Text paddingLeft={2} fontFamily="Poppins">800.260.5051</Text>
                            </Flex>
                            <Box backgroundColor={"gray.200"} width="1px"/>
                            <Link color={generalColor.primaryText} fontFamily="Poppins" fontWeight={300}
                                  href="mailto:info@akdealerservices.com" _hover={{textDecoration: "underline"}}
                                  paddingLeft={2}>
                                info@akdealerservices.com
                            </Link>
                        </Flex>

                    </Box>
                    {!isAuth &&
                        <Box {...BoxStyleConfig} >
                            <Icon as={BannerIcon} onClick={() => navigate(URLPaths.GREAT_SAVE.link)} width="300px"
                                  p={"1px"}
                                  height={"100%"} cursor={"pointer"}
                                  color="gray.200"
                                  _hover={{color: "gray.500"}}
                            />
                        </Box>
                    }

                    <Box {...BoxStyleConfig} >
                        <Flex fontSize="14px" direction={{base: "column", lg: "row"}} justifyContent="flex-end">
                            {/* Пустой Box с flexGrow для вытеснения остальных элементов вправо */}
                            <Box flexGrow={1}></Box>
                            <Flex minHeight="40px">
                                {/*<PopoverMenu/>*/}
                                {isAuth ?
                                    <Flex h="100%" alignItems="center">
                                        <Text pr={3} fontSize={14}
                                              fontWeight={"md"}>{sessionStorage.getItem("Name")}</Text>
                                    </Flex>
                                    :
                                    <MenuButton onClick={() => {
                                        if (!location.pathname.includes(URLPaths.AUTH.link)) {
                                            navigate(URLPaths.AUTH.link + location.pathname);
                                        }
                                    }}/>
                                }
                                {isAuth && dealerships.length > 2 && (
                                    <Box pr={3}>
                                        <Tooltip
                                            label={authDealership === undefined ? "no selected" : authDealership?.name}>
                                            <Select
                                                icon={<ChevronDownIcon fontSize={24}/>}
                                                borderColor={blink ? "green" : "red"}
                                                value={val}
                                                fontSize={14}
                                                w={200}
                                                pa={5}
                                                onChange={(ev) => {
                                                    updateDealership(ev.target.value);
                                                    ev.target.blur();
                                                }}
                                            >
                                                {dealerships?.map((elem) => (
                                                    <option key={elem.id} value={elem.id} hidden={elem.id === 0}>
                                                        {elem.name}
                                                    </option>
                                                ))}
                                            </Select>
                                        </Tooltip>
                                    </Box>
                                )}
                            </Flex>
                            <PopoverCart/>
                            {/*<CartBasketDrawer/>*/}
                            {(location.pathname.includes(URLPaths.PRODUCTS.link) || location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.CART.link) && (
                                <Box paddingLeft={{base: 0, lg: 4}} ml={{lg: "auto"}} mr={{lg: "auto"}}
                                     pt={{base: 2, lg: 0}}>
                                    <Filter
                                        updateFunc={setNewFilterValue}
                                        uniqueName="search"
                                        reset={resetFilter}
                                        height="40px"
                                        placeholder="Search"
                                        style={{border: "1.6px solid", borderColor: "gray.600"}}
                                    />
                                </Box>
                            )}
                        </Flex>
                        {isAuth &&
                            <Flex ml={"auto"} flexDir={"column"}>
                                <Flex ml={"auto"} pt={1}>
                                    <NavBarLinkButton fontSize={14} fontWeight={"sm"}
                                                      text={'Order History'}
                                                      onClick={() => navigate(URLPaths.ORDER_HISTORY.link)}/>
                                    {(priv?.USER_CREATE || priv?.USER_VIEW) && (
                                        <>
                                            <Box backgroundColor={"gray.200"} width="1px"/>
                                            <NavBarLinkButton pl={1} fontSize={14} fontWeight={"sm"}
                                                              text={'Company list'}
                                                              onClick={() => navigate(URLPaths.COMPANIES.link)}/>
                                            {newUserCount !== 0 &&
                                                <Flex color={'white'} background={'red'} borderRadius={10} boxSize={5}
                                                      alignItems='center' justifyContent={"center"}
                                                      fontSize={14}>{newUserCount}</Flex>
                                            }
                                        </>
                                    )}
                                </Flex>
                                <Flex fontSize={14} ml={"auto"} pt={1}>
                                    {!priv?.ADMINISTRATOR_VIEW && (
                                        <>
                                            <NavBarLinkButton fontSize={14} fontWeight={"sm"}
                                                              text={'Account'}
                                                              onClick={() => navigate(URLPaths.ACCOUNT.link)}/>
                                            <Box backgroundColor={"gray.200"} width="1px"/>
                                        </>
                                    )}
                                    {priv?.ROLE_VIEW && (
                                        <>
                                            <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                              text={'Role list'}
                                                              onClick={() => navigate(URLPaths.ROLES.link)}/>
                                            <Box backgroundColor={"gray.200"} width="1px"/>
                                        </>
                                    )}
                                    <NavBarLinkButton fontSize={14} fontWeight={"sm"} pl={1}
                                                      text={'Log Out'}
                                                      onClick={LogOut}/>
                                </Flex>
                            </Flex>
                        }
                    </Box>
                </Flex>
            }
        </Flex>
    );
};
