import React, {FC, useEffect, useState} from "react";
import {Box, Center, Container, Flex, Image, Link as ChakraLink} from "@chakra-ui/react";
import {URLPaths} from "../../config/application/URLPaths";
import {WebPUtils} from "../../shared/utilities/WebPUtils";
import {ImageItem, Product} from "../../shared/entities/Product/Product";
import {imageSizes} from "../../theme/foundations/breakpoints";
import ReactDOM from "react-dom";
import {useLocation, useNavigate} from "react-router";
import {BoxRowStyleConfig} from "../../views/Forms/FormStyleConfigs";
import {Icon} from "@chakra-ui/icons";
import {useDispatch} from "react-redux";
import {ActionTypes} from "../../redux/NavBar/NavBarReducer";
import {FiChevronLeft, FiChevronRight} from "react-icons/all";
import imgGifMock from "../../assets/img/200w.gif";
import {Link as ReactRouterLink} from "react-router-dom";

interface ImageCustom {
    sizeInPercent?: number;
    elem: Product | null;
    showOnHover?: boolean;
    uploadImages?: Array<ImageItem>;
    upload?: boolean;
    onIndexChange?: (index: number) => void;
    parentCurrentIndex?: number;
    hideArrows?: boolean;
    noHandleLink?: boolean;
    currentSize?: keyof typeof imageSizes;
    imageIndex?: number;
    showPreview?: boolean;
    sizeBackend?: string;
    isMobail?: boolean;
}

const ImageCustom: FC<ImageCustom> = ({
                                          uploadImages,
                                          elem,
                                          sizeInPercent = 100,
                                          imageIndex = 0,
                                          showOnHover = true,
                                          upload = false,
                                          onIndexChange,
                                          parentCurrentIndex,
                                          hideArrows = true,
                                          noHandleLink = false,
                                          showPreview = false,
                                          currentSize = "original",
                                          sizeBackend = "ORIGINAL",
                                          isMobail = false
                                      }) => {
    const location = useLocation();
    const isHomePage = (location.pathname === URLPaths.HOME.link || location.pathname === URLPaths.HOME_ARCHIVE.link);
    const [isHovered, setIsHovered] = useState<boolean>(false);
    const [mousePosition, setMousePosition] = useState({x: 0, y: 0});
    const [isMounted, setIsMounted] = useState(true);
    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState<number>(imageIndex);
    const [images, setImages] = useState<Array<ImageItem>>([]);
    const dispatch = useDispatch();

    function findIndexByUuid(item: Product): number {
        if (item && item.primaryImageUuid && item.images && item.images.length > 0) {
            return item?.images.findIndex(image => image.uuid === item.primaryImageUuid);
        }
        return 0
    }

    useEffect(() => {
        if (uploadImages && uploadImages.length > 0) {
            let setDefault = 0;
            if (uploadImages.filter(item => !item.file).length === uploadImages.length) {
                if (elem && elem.images) {
                    setDefault = findIndexByUuid(elem);
                    setCurrentIndex(Number(setDefault));
                }
            }
            setImages(uploadImages);
            if (parentCurrentIndex && parentCurrentIndex !== currentIndex) {
                setCurrentIndex(parentCurrentIndex);
            }
            if (parentCurrentIndex === 0 && setDefault === 0) {
                setCurrentIndex(parentCurrentIndex);
            }
        } else {
            if (elem && elem.images && elem.images.length > 0 && !upload) {
                const newImages = elem.images.map((image) => ({
                    url: `${WebPUtils.getProxy()}file/${image.uuid}?size=${sizeBackend}&format=${WebPUtils.canUseWebP()}`,
                    file: null,
                    uuid: image.uuid
                }));
                setCurrentIndex(findIndexByUuid(elem));
                setImages(newImages)
            } else {
                setImages([])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elem, uploadImages])
    useEffect(() => {
        setCurrentIndex(imageIndex);
    }, [imageIndex])

    const handleArrowClick = (direction: 'left' | 'right') => {
        if (images.length === 0) {
            return;
        }
        if (direction === 'left') {
            setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
        } else {
            setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
        }
    };

    useEffect(() => {
        if (onIndexChange) {
            onIndexChange(currentIndex);
        }
    }, [currentIndex, onIndexChange]);

    let hoverTimeout: NodeJS.Timeout | null = null;

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsHovered(false);
        hoverTimeout = setTimeout(() => {
            setIsHovered(true);
            setMousePosition({x: e.clientX, y: e.clientY});
        }, 100);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        if (hoverTimeout) {
            clearTimeout(hoverTimeout);
        }
    };

    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);

    const handleImageClick = (elem: Product | null) => {
        if (isMounted && elem && !noHandleLink) {
            dispatch({type: ActionTypes.SEARCH_RESET, payload: true});
            navigate(`${URLPaths.PRODUCTS.link}${elem.id}`)
        }
    };

    const [loading, setLoading] = useState(true);

    const handleImageLoad = () => {
        setLoading(false); // Устанавливаем флаг загрузки в false при успешной загрузке изображения
    };

    const handleImageError = () => {
        setLoading(false); // Устанавливаем флаг загрузки в false при ошибке загрузки изображения
    };
    const borderRadiusMap = {
        small: {borderRadius: "sm"},
        medium: {borderRadius: "md"},
        large: {borderRadius: "lg"},
        original: {borderRadius: "default"},
        subgeneral: {
            borderTopRightRadius: "lg",
            borderTopLeftRadius: "lg",
        },
        general: {borderRadius: "lg"},
        cardPreview: {borderRadius: "sm"},
        another: {borderTopRightRadius: "lg"},
        additional: {borderRadius: "default"},
        cart: {borderRadius: "default"},
        default: {borderRadius: "default"},
    };

    const getBorderRadius = (currentSize: keyof typeof imageSizes) => {
        return borderRadiusMap[currentSize] || borderRadiusMap.default;
    };

    return (
        <Box onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}>
            {images.length > 0 && (
                <Flex alignItems="start" flexDirection="column"
                      userSelect="none">
                    {images[currentIndex] && (
                        <Box {...BoxRowStyleConfig}
                             alignItems="center"
                             justifyContent="center"
                             border={upload ? "1px" : "default"}>
                            {!hideArrows && (images.length > 1 && (isHovered || isMobail)) &&
                                <Icon
                                    as={FiChevronLeft}
                                    fontSize={24}
                                    cursor={"pointer"}
                                    onClick={() => handleArrowClick('left')}
                                    _hover={{color: "gray.500"}}
                                />
                            }
                            <Flex
                                width={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].width * sizeInPercent / 100}px`}
                                height={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].height * sizeInPercent / 100}px`}
                            >
                                <ChakraLink to={`${URLPaths.PRODUCTS.link}${elem?.id}`} as={ReactRouterLink}>
                                    <Image
                                        fallbackSrc={imgGifMock}
                                        src={images[currentIndex].url}
                                        alt={elem?.name}
                                        {...getBorderRadius(currentSize)}
                                        objectFit={'contain'}
                                        // width={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].width * sizeInPercent / 100}px`}
                                        // height={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].height * sizeInPercent / 100}px`}
                                        // width={"10
                                        // 0%"}
                                        // height={`${imageSizes[currentSize].height * sizeInPercent / 100}px`}
                                        // width="100%"
                                        // height="100%"
                                        onClick={() => handleImageClick(elem)}
                                        cursor={"pointer"}
                                        onLoad={handleImageLoad} // Обработчик успешной загрузки изображения
                                        onError={handleImageError} // Обработчик ошибки загрузки изображения
                                    />
                                </ChakraLink>
                            </Flex>
                            {!hideArrows && (images.length > 1 && (isHovered || isMobail)) &&
                                <Icon
                                    as={FiChevronRight}
                                    fontSize={24}
                                    cursor={"pointer"}
                                    onClick={() => handleArrowClick('right')}
                                    _hover={{color: "gray.500"}}
                                />
                            }
                        </Box>
                    )}
                </Flex>
            )}
            {isHovered && !isHomePage && showOnHover &&
                ReactDOM.createPortal(
                    <Box position="fixed"
                         top={`${mousePosition.y}px`}
                         left={`${mousePosition.x}px`}
                         zIndex={9999}
                    >
                        <Image
                            src={images[currentIndex].url}
                            alt={`Selected ${currentIndex + 1}`}
                            // height={`${imageSizes[currentSize].width * 100 / 100}px`}
                            height={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].width * 100 / 100}px`}
                            width={isMobail ? `${sizeInPercent}%` : `${imageSizes[currentSize].height * 100 / 100}px`}
                            // width={"100%"}
                        />
                    </Box>,
                    document.body
                )}
        </Box>
    );
}

export default ImageCustom;
